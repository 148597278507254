import { combine } from "effector";

import { getCountriesDetailsByIdQuery } from "@/shared/model/api/common";
import { getHotelDetailsQuery } from "@/shared/model/api/hotels";
import { $toursData } from "@/shared/model/store/search";

export const $minPrice = $toursData.map((data) => {
  const prices = data?.data?.map((tour) => tour?.price) ?? [];

  if (prices.length === 0) {
    return 0;
  }

  return Math.min(...prices);
});

export const $hotelData = getHotelDetailsQuery.$data.map((v) => v);

export const $selectedArrivalText = combine(
  getCountriesDetailsByIdQuery.$data,
  (data) =>
    data ? `${data.preposition ?? "в"} ${data.case_forms.accusative}` : "...",
);

export const $hotelDescriptionBlocks = getHotelDetailsQuery.$data.map((v) =>
  v?.descriptions
    ? v.descriptions
        .flatMap(({ name, blocks }) => ({
          blocks,
          title: name,
        }))
        .filter((category) => Boolean(category.blocks.length))
    : [],
);
