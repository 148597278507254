"use client";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";

import { styles } from "../styles";

export const Close = ({ onClick, isPhone }) => {
  const stylesKey = isPhone ? "mobile" : "desktop";

  return (
    <IconButton
      onClick={onClick}
      sx={[styles.controlButton, styles.controlButton[stylesKey]]}
    >
      <CloseIcon sx={[styles.controlIcon, styles.controlIcon[stylesKey]]} />
    </IconButton>
  );
};
