import * as CSS from "csstype";
import { COLORS } from "shared/ui/styles";

import { createGradient } from "@/shared/lib/helpers";
import { mainTheme } from "@/shared/ui/styles/theme/mainTheme";

const gradient: CSS.Properties = createGradient();

type TMobileSwipeableDrawerProps = {
  mapIsOpen: boolean;
  drawerHeight: number;
  drawerBleeding: number;
};

// TODO: move styles for appropriate files within folders in UI folder

export const styles = {
  zoomControlsWrapper: {
    gap: "8px",
    display: "flex",
    flexDirection: "column",
  },

  categoryTag: {
    width: "fit-content",
    color: "accent.brand.primary",
    backgroundColor: "accent.brand.lightest",
  },

  locationIcon: {
    svg: {
      width: "32px",
      height: "32px",
      color: "content.primary",
      background: "content.primary",
    },
  },
  controlIcon: {
    color: "black",

    mobile: {
      width: "24px",
      height: "24px",
    },

    desktop: {
      width: "18px",
      height: "18px",
    },
  },

  officePlacemark: {
    width: "20px",
    height: "20px",
    borderRadius: "50%",
    border: "2px solid",
    borderColor: "dominant.white",
    backgroundColor: "accent.brand.primary",
  },

  mobileControlsWrapper: {
    top: 32,
    zIndex: 1,
    width: "100%",
    position: "absolute",

    controls: {
      display: "flex",
      padding: "0 16px",
      justifyContent: "space-between",
    },
  },
  controlButton: {
    padding: "4px",
    transition: "0.2s",
    borderRadius: "100px",
    backgroundColor: "dominant.main",
    boxShadow: "0px 0px 15px rgba(74, 85, 104, 0.15)",

    "&:hover": {
      backgroundColor: "cloud",
    },

    "&:focus": {
      backgroundColor: "grey.white",
    },

    mobile: {
      width: "42px",
      height: "42px",
    },

    desktop: {
      width: "36px",
      height: "36px",
    },
  },
  officeBalloon: {
    gap: "12px",
    left: "50%",
    zIndex: "1",
    width: "100%",
    bottom: "32px",
    display: "flex",
    maxWidth: "343px",
    borderRadius: "8px",
    position: "absolute",
    backgroundColor: "white",
    padding: "16px !important",
    transform: "translateX(-50%)",
    boxShadow: "0px 0px 10px rgba(74, 85, 104, 0.45)",

    [mainTheme.breakpoints.down("md")]: {
      width: "calc(100% - 32px)",
    },
  },

  filtersButton: {
    gap: "10px",

    padding: "4px",
    width: "112px",
    display: "flex",
    transition: "0.2s",
    borderRadius: "100px",
    backgroundColor: "dominant.main",
    mobile: {
      height: "42px",
    },

    desktop: {
      height: "36px",
    },

    boxShadow: "0px 0px 15px rgba(74, 85, 104, 0.15)",

    "&:hover": {
      backgroundColor: "cloud",
    },

    "&:focus": {
      backgroundColor: "grey.white",
    },

    label: {
      color: "#04172C",
      fontSize: "14px",
      fontWeight: "400",
      lineHeight: "16px",
    },
  },

  mobileSwipeableDrawer: {
    paper: {
      display: "block",
      boxShadow: "none",
      backgroundColor: "transparent",
    },

    swipeableArea: {
      display: "flex",
      marginBottom: "8px",
      justifyContent: "center",

      strip: {
        height: "8px",
        width: "109px",
        borderRadius: "16px",
        backgroundColor: "#CCD5DE",
      },
    },

    content: ({
      mapIsOpen,
      drawerBleeding,
    }: Omit<TMobileSwipeableDrawerProps, "drawerHeight">) => ({
      left: 0,
      right: 0,
      top: -drawerBleeding,
      position: "absolute",
      visibility: mapIsOpen ? "visible" : "hidden",
    }),

    wrapper: ({
      mapIsOpen,
      drawerHeight,
      drawerBleeding,
    }: TMobileSwipeableDrawerProps) => ({
      top: "auto",
      zIndex: 1300,

      ".MuiPaper-root.MuiPaper-root": {
        overflow: mapIsOpen ? "visible" : "hidden",
        height: `calc(${drawerHeight}px - ${drawerBleeding}px)`,
      },
    }),
  },

  placemark: {
    mobile: (active: boolean) => ({
      color: "#fff",
      fontSize: "12px",
      padding: "6px 8px",
      borderRadius: "4px",
      width: "fit-content",
      position: "relative",
      display: "inline-block",
      backgroundColor: active ? "accent.brand.secondary" : "content.secondary",

      "&::after": {
        width: "0",
        height: "0",
        top: "100%",
        left: "40%",
        clear: "both",
        content: '""',
        position: "absolute",
        borderTop: "6px solid",

        borderBottom: "0px solid",
        borderLeft: "6px solid transparent",
        borderRight: "6px solid transparent",
        borderTopColor: active ? "accent.brand.secondary" : "content.secondary",

        borderBottomColor: active
          ? "accent.brand.secondary"
          : "content.secondary",
      },
    }),

    desktop: (active: boolean) => ({
      fontSize: "12px",
      padding: "6px 8px",
      borderRadius: "4px",
      width: "fit-content",
      position: "relative",
      color: "dominant.white",
      display: "inline-block",
      backgroundColor: active ? "accent.brand.secondary" : "content.secondary",

      "&::after": {
        width: "0",
        height: "0",
        left: "40%",
        clear: "both",
        content: '""',
        position: "absolute",
        top: active ? "-6px" : "100%",
        borderLeft: "6px solid transparent",

        borderRight: "6px solid transparent",
        borderTop: active ? "0" : "6px solid",
        borderBottom: active ? "6px solid" : "0px solid",
        borderTopColor: active ? "accent.brand.secondary" : "content.secondary",

        borderBottomColor: active
          ? "accent.brand.secondary"
          : "content.secondary",
      },
    }),
  },

  balloon: {
    price: {
      fontWeight: 500,
      fontSize: "18px",
      lineHeight: "22px",
    },

    title: {
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "18px",
    },
    desktop: {
      width: "254px",
      display: "flex",
      flexDirection: "column",

      buyButton: {
        width: "100%",
        marginTop: "12px",
      },
      price: {
        fontWeight: 500,
        fontSize: "18px",
        lineHeight: "22px",
      },
      title: {
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "18px",
      },
      contentWithImageWrapper: {
        marginTop: "6px",
        borderRadius: "12px",
        boxShadow: "0px 4px 16px rgba(74, 85, 104, 0.15)",
      },
      imagePlaceholderIcon: {
        width: "90px",
        opacity: "0.8",
        height: "90px",
        fill: "transparent",
        color: "grey.default",
      },
      imageWrapper: {
        width: "100%",
        height: "136px",
        flex: "0 0 auto",
        overflow: "hidden",
        borderRadius: "16px 16px 0 0",
      },
      imagePlaceholder: {
        width: "230px",
        display: "grid",
        height: "136px",
        flex: "0 0 auto",
        position: "relative",
        placeContent: "center",
        borderTopLeftRadius: "12px",
        borderTopRightRadius: "12px",
        backgroundColor: "grey.white",
      },
      content: {
        width: "230px",
        padding: "12px",
        display: "inline-block",
        borderBottomLeftRadius: "12px",
        borderBottomRightRadius: "12px",
        backgroundColor: "dominant.white",

        headingBox: {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        },
      },
      image: {
        width: "100%",
        height: "100%",

        display: "flex",

        alignItems: "center",
        justifyContent: "center",
        borderTopLeftRadius: "12px",
        maxWidth: "unset !important",

        backgroundPosition: "center",
        borderTopRightRadius: "12px",
        backgroundRepeat: "no-repeat",
        ...gradient,
      },
    },
    mobile: {
      width: "100%",
      display: "flex",
      border: "solid 1px",
      borderRadius: "12px",
      flexDirection: "column",
      borderColor: "content.grey-100",

      price: {
        fontWeight: 500,
        fontSize: "18px",
        lineHeight: "22px",
      },
      title: {
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "18px",
        margin: "2px 0 0 0",
      },
      contentWithImageWrapper: {
        marginTop: 0,
        borderRadius: "12px",
        boxShadow: "0px 4px 16px rgba(74, 85, 104, 0.15)",
      },
      imagePlaceholderIcon: {
        width: "90px",
        opacity: "0.8",
        height: "90px",
        fill: "transparent",
        color: "grey.default",
      },
      buyButton: {
        width: "100%",
        fontWeight: 400,
        fontSize: "16px",
        marginTop: "4px",
        padding: "12px 0",
        lineHeight: "18px",
      },
      imageWrapper: {
        height: "150px",
        flex: "0 0 auto",
        overflow: "hidden",
        borderTopLeftRadius: "12px",
        borderTopRightRadius: "12px",
      },
      imagePlaceholder: {
        width: "100%",
        display: "grid",
        height: "150px",
        flex: "0 0 auto",
        position: "relative",
        placeContent: "center",
        borderTopLeftRadius: "12px",
        borderTopRightRadius: "12px",
        backgroundColor: "grey.white",
      },
      content: {
        padding: "12px",
        display: "inline-block",
        width: "calc(100% - 25px)",
        borderBottomLeftRadius: "12px",
        borderBottomRightRadius: "12px",
        backgroundColor: "dominant.white",

        headingBox: {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        },
      },

      image: {
        width: "100%",
        height: "100%",
        display: "flex",
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        background: gradient,
        alignItems: "center",
        justifyContent: "center",

        backgroundSize: "400% 400%",
        borderTopLeftRadius: "12px",
        backgroundPosition: "center",

        borderTopRightRadius: "12px",
        backgroundRepeat: "no-repeat",
        animation: "gradient 5s ease infinite",

        "@keyframes gradient": {
          "0%": {
            backgroundPosition: "0% 50%",
          },
          "100%": {
            backgroundPosition: "0% 50%",
          },
          "50%": {
            backgroundPosition: "100% 50%",
          },
        },
      },
    },
  },
};

export const tourCard = {
  swiperButtonLeft: {
    left: "12px",
  },
  swiperButtonRight: {
    right: "12px",
  },
  footerDesktop: {
    width: "100%",
    maxWidth: "60%",
  },
  lastDayTour: { color: "error.main", backgroundColor: "error.light" },

  hr: {
    width: "100%",
    height: "1px",
    background: COLORS.CLOUD,
  },

  swiper: {
    inset: 0,
    zIndex: 0,
    height: "100%",
    position: "absolute",
  },

  additionalTags: {
    color: "primary.dark",
    backgroundColor: "primary.whiteblue",
  },
  rating: {
    fontWeight: 400,
    fontSize: "18px",
    lineHeight: "21px",
    color: "grey.light",
  },

  amenityBadge: {
    gap: "8px",
    display: "flex",
    color: COLORS.GRAY,
    whiteSpace: "nowrap",
  },
  categoryTag: {
    width: "fit-content",
    color: "accent.brand.primary",
    backgroundColor: "accent.brand.lightest",
  },
  additionalTagsBox: {
    gap: "4px",
    display: "flex",
    flexWrap: "wrap",
    padding: "6px 0",
    marginTop: "auto",
  },
  tooltip: {
    width: "30px",
    height: "30px",
    display: "flex",
    alignItems: "center",
    color: "grey.extralight",
  },
  imagePlaceholder: {
    width: "90px",
    opacity: "0.8",
    height: "90px",
    fill: "transparent",
    color: "grey.default",
  },

  imagePlaceholderContainer: {
    display: "grid",
    position: "relative",
    placeContent: "center",
    backgroundColor: "grey.white",
  },
  priceWithoutDiscount: {
    fontSize: 14,
    fontWeight: 400,
    color: "#ED5D5D",
    lineHeight: "16px",
    textDecoration: "line-through",
  },

  image: {
    width: "100%",
    height: "100%",
    userSelect: "none",
    objectFit: "cover",
    WebkitUserDrag: "none",
    objectPosition: "center",
  },

  flexContainer: {
    width: "100%",
    display: "flex",
    marginTop: "auto",
    flexDirection: "row",
    alignItems: "flex-end",
    justifyContent: "space-between",
  },

  imageContainer: {
    width: "200px",
    flex: "0 0 auto",
    ...gradient,

    "@media screen and (max-width: 756px)": {
      height: "220px",
      width: "stretch",
    },
  },

  overlay: {
    top: 0,
    left: 0,
    right: 0,
    zIndex: 1,
    height: "60px",
    position: "absolute",
    background: "linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0))",
  },

  swiperButton: {
    zIndex: 1,
    opacity: 0,
    top: "50%",
    position: "absolute",
    pointerEvents: "none",
    transform: "translateY(-50%)",
    transition: "opacity .3s ease",
  },

  swiperSlide: {
    flexShrink: 0,
    height: "auto",

    favoriteIcon: {
      top: "4.17%",
      right: "4.17%",
      bottom: "12.5%",
      cursor: "pointer",
      position: "absolute",
    },
  },

  buyButton: {
    height: "unset",
    fontWeight: 500,
    fontSize: "12px",
    marginTop: "14px",
    lineHeight: "14px",
    padding: "8px 16px",

    toursButton: {
      marginTop: "4px",
      width: "stretch",
    },
  },

  defaultImageWrapper: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    position: "absolute",
    justifyContent: "center",
    backgroundColor: "grey.white",
  },
  dateContainer: {
    fontSize: 12,
    fontWeight: 500,
    textAlign: "end",
    maxWidth: "100%",
    lineHeight: "16x",
    color: "grey.light",

    "@media screen and (max-width: 756px)": {
      maxWidth: "65%",
      fontWeight: 400,
      color: "grey.dark",
      lineHeight: "14px",
    },
  },
  tourInfoMobile: {
    gap: "6px",
    padding: "0px",
    display: "flex",
    marginTop: "8px",
    marginBottom: "7px",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",

    row: {
      gap: "10px",
      display: "flex",
      alignItems: "center",
    },
  },

  price: {
    fontSize: 24,
    fontWeight: 500,
    display: "flex",
    lineHeight: "28px",
    alignItems: "flex-end",

    "@media screen and (max-width: 756px)": {
      fontSize: 20,
      fontWeight: 500,
      lineHeight: "24px",
    },

    "@media screen and (max-width: 1100px)": {
      fontSize: 20,
      fontWeight: 500,
      lineHeight: "24px",
    },
  },

  swiperContainer: {
    position: "relative",
    "&:hover .swiper-button": {
      opacity: "1 !important",
      pointerEvents: "auto !important",
    },
    "& .swiper-pagination-bullet-active": {
      width: "14px",
      borderRadius: "100px",
      backgroundColor: "dominant.white",
    },
    "& .swiper-pagination-bullet": {
      opacity: 1,
      backgroundColor: "rgba(255,255,255, 0.6)",
      transition: "width .3s, background-color .4s",
    },
  },

  deferredPayment: {
    fontSize: 12,
    fontWeight: 400,
    color: "#767676",
    lineHeight: "16px",

    "@media screen and (max-width: 756px)": {
      fontSize: 14,
    },

    tooltipText: {
      fontSize: 10,
      fontWeight: 400,
      lineHeight: "12px",
    },

    tooltipTitle: {
      fontSize: 12,
      fontWeight: 500,
      lineHeight: "16px",
    },

    tooltip: {
      width: "156px",
      position: "absolute",
      inset: "auto auto -12px -7px !important",
    },
  },

  card: {
    width: "100%",
    display: "flex",
    minHeight: "220px",
    overflow: "hidden",
    border: "1px solid",
    borderRadius: "8px",
    position: "relative",
    marginBottom: "24px",
    borderColor: "grey.white",
    justifyContent: "space-between",
    transition: "box-shadow .3s ease",
    backgroundColor: "dominant.white",

    "&:last-child": {
      marginBottom: 0,
    },

    "&:hover": {
      border: "1px solid #2b6bb2",
      boxShadow: "0px 0px 15px rgba(74, 85, 104, 0.1)",
    },

    "@media screen and (max-width: 756px)": {
      display: "inherit",
      borderRadius: "12px",
      filter: "drop-shadow(0px 2px 3px rgba(14, 38, 65, 0.14))",
    },

    "@media screen and (min-width: 756px) and (max-width: 980px)": {
      height: "262px",
      display: "grid",
      marginBottom: "16px",
      borderRadius: "12px",
      gridTemplateColumns: "1fr 2fr",
      filter: "drop-shadow(0px 2px 3px rgba(14, 38, 65, 0.14))",
    },
  },

  contentWrapper: {
    width: "100%",
    color: "unset",
    display: "flex",
    padding: "16px",
    columnGap: "16px",
    position: "relative",
    textDecoration: "none",
    flexDirection: "column",
    justifyContent: "space-between",
    gridTemplateColumns: "minmax(0, 410px) minmax(0, 50%)",

    amenitiesWrapper: {
      width: "100%",
      height: "100%",
      overflow: "hidden",
    },

    "@media screen and (max-width: 756px)": {
      padding: "12px",
      display: "flex",
      cursor: "pointer",
      height: "calc(100% - 220px)",
      justifyContent: "space-between",
    },

    amenities: {
      rowGap: "4px",
      display: "flex",
      marginBottom: "10px",
      alignSelf: "flex-start",
      flexDirection: "column",

      "@media screen and (max-width: 756px)": {
        gap: "12px",
        padding: "0px",
        display: "flex",
        width: "stretch",
        overflowX: "auto",
        overflowY: "hidden",
        marginBottom: "8px",
        flexDirection: "row",
        scrollbarWidth: "none",
        gridTemplateColumns: "0",
        justifyContent: "flex-start",

        "&::-webkit-scrollbar": {
          display: "none",
        },
      },
    },
  },

  tagsWrapper: {
    gap: "8px",
    zIndex: "1",
    display: "flex",
    padding: "12px",
    flexWrap: "wrap",
    position: "absolute",
    flexDirection: "column",

    favoriteIcon: {
      zIndex: 0,
      top: "13px",
      padding: "0",
      width: "24px",
      right: "13px",
      cursor: "pointer",
      position: "absolute",
    },

    "@media screen and (max-width: 756px)": {
      gap: "6px",
      height: "22px",
      width: "220px",
      display: "flex",
      flexWrap: "wrap",
      flexDirection: "row",
      position: "absolute",
    },

    burningTour: {
      width: "fit-content",
      color: "error.secondary",
      backgroundColor: "accent.red.light",

      "@media screen and (max-width: 756px)": {
        height: "22px",
        fontSize: "12px",
      },
    },

    favoriteIconLoading: {
      transition: "0.2s",

      animation: "heartbeat 0.5s infinite linear",

      "@keyframes heartbeat": {
        to: {
          transform: "scale(0.9)",
        },
        from: {
          transform: "scale(1.1)",
        },
      },
    },

    favoriteIconDesktop: {
      zIndex: 0,
      top: "17px",
      padding: "0",
      width: "24px",
      right: "17px",
      display: "flex",
      cursor: "pointer",
      transition: "0.2s",
      alignItems: "center",
      position: "absolute",
      justifyContent: "center",

      "&:active": {
        transform: "scale(0.9)",
      },
    },
  },

  heading: {
    marginBottom: "12px",

    "@media screen and (max-width: 756px)": {
      marginBottom: "0",
    },
    titleWrapper: {
      gap: "8px",
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
    },

    title: {
      gap: "8px",
      color: "black",
      maxWidth: "45ch",
      fontSize: "18px",
      display: "inline",
      cursor: "pointer",
      textDecoration: "none",
      transition: "color .3s ease",

      "&:hover": {
        color: "primary.main",
      },

      "@media screen and (max-width: 756px)": {
        width: "253px",
        fontWeight: 500,
        maxWidth: "100%",
        fontSize: "18px",
        lineHeight: "22px",
        paddingBottom: "8px",
      },
    },

    headingBox: {
      display: "flex",
      marginBottom: "10px",
      alignItems: "center",
      justifyContent: "space-between",

      ratingComponent: {
        verticalAlign: "bottom",
      },

      "@media screen and (max-width: 756px)": {
        display: "flex",
        marginBottom: "9px",
        justifyContent: "space-between",
      },

      rating: {
        right: "4.17%",
        fontWeight: 400,
        margin: "revert",
        fontSize: "18px",
        lineHeight: "21px",
        color: "grey.light",
        position: "absolute",

        "@media not all and (max-height: 899px) and (min-resolution:.1dpi)": {
          top: "initial",
        },
      },
    },

    address: {
      columnGap: "3px",
      marginTop: "4px",
      lineHeight: "12px",
      alignItems: "center",
      color: "grey.content",
      display: "inline-flex",

      "@media screen and (max-width: 756px)": {
        width: "100%",
        fontSize: "12px",
        lineHeight: "14px",
        color: COLORS.LIGHT_GRAY,
      },

      tooltip: {
        maxWidth: "90%",
        marginBottom: 0,
        marginTop: "2px",
        fontSize: "14px",
        overflow: "hidden",
        lineHeight: "16px",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        color: COLORS.LIGHT_GRAY,
        overflowWrap: "break-word",

        showAddress: {
          cursor: "pointer",
          color: COLORS.BLUE,
        },
      },
    },
  },
};
