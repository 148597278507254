import { Box } from "@mui/material";

import { styles } from "../styles";

import PlacemarkIcon from "@/public/icons/map/placemark.svg";

export const OfficePlacemarkLayout = ({ isActive }) => {
  if (isActive) return <PlacemarkIcon />;

  return <Box sx={styles.officePlacemark} />;
};
