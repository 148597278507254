export const styles = {
  officeName: {
    fontSize: "16px",
  },
  officeAddress: {
    fontSize: "14px",
    color: "content.grey-400",
  },
  officeSchedule: {
    fontSize: "14px",
    color: "content.grey-400",
  },
  placemarkWrapper: {
    gap: "4px",
    display: "flex",
    flexDirection: "column",
  },
};
