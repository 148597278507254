"use client";
import {
  FC,
  memo,
  useRef,
  useMemo,
  useState,
  useEffect,
  useCallback,
} from "react";

import { SxProps } from "@mui/material";
import Box from "@mui/material/Box";
import { Map, YMaps, Placemark, ZoomControl } from "@pbe/react-yandex-maps";
import { Map as TMap } from "@pbe/react-yandex-maps/typings/Map";
import { useUnit } from "effector-react";
import { useLocale, useMessages } from "next-intl";

import { TLocations } from "@/pagesLayer/payment/[orderNumber]/model/paymentTypes";

import {
  ZOOM_TYPE,
  PLACEMARK_TYPE,
  DEFAULT_LOCATION,
} from "@/entities/map/lib/constants";
import {
  GetLayout,
  ZoomLayout,
  OfficePlacemarkLayout,
  OfficePlacemarkBalloonLayout,
} from "@/entities/map/ui";

import { YANDEX_MAPS_API_KEY } from "@/shared/config";
import { sendDataLayerEvent } from "@/shared/model/analytics";

type TProps = {
  sx?: SxProps;
  officesData: TLocations[];
};

export const OfficesMap: FC<TProps> = memo(({ sx, officesData }) => {
  const sendDataLayerEventFn = useUnit(sendDataLayerEvent);
  const messages = useMessages();
  const locale = useLocale();

  const [ymaps, setYmaps] = useState<any>(null);
  const [activeSlideIndex, setActiveSlideIndex] = useState<null | number>(null);
  const mapRef = useRef<any>(null);

  const mapData = useMemo(() => {
    setActiveSlideIndex(null);
    if (!officesData) {
      return null;
    }
    return officesData
      .filter((office) => office?.lat && office?.lon)
      .map(({ id, lon, lat, name, address, schedule }) => {
        return {
          id,
          name,
          address,
          schedule,
          coordinates: [lat, lon],
        };
      });
  }, [officesData]);

  useEffect(() => {
    const foundCoordinates = mapData?.find(
      (item) => item.coordinates.filter((item) => item)?.length,
    )?.coordinates;

    if (!mapRef || !foundCoordinates) return;

    mapRef?.current?.setCenter(foundCoordinates, 12, {
      checkZoomRange: true,
    });
  }, [mapData, mapRef]);

  const setupMap = (ymaps: TMap) => {
    setYmaps(ymaps);
  };

  // because first tour with 0 index might not have coordinates
  const firstOfficeWithCoordinates = useMemo(() => {
    return mapData?.find((office) => office.coordinates)?.coordinates;
  }, [mapData]);

  const onPlacemarkClick = useCallback(
    (id: string, active: boolean) => {
      const targetSlideIndex = mapData?.findIndex((item) => item.id === id);

      if (targetSlideIndex === -1) return;

      sendDataLayerEventFn({
        // @ts-expect-error
        event: mapData?.[targetSlideIndex as number].name,
      });

      if (!active && targetSlideIndex !== activeSlideIndex) {
        setActiveSlideIndex(targetSlideIndex as number);
      } else {
        setActiveSlideIndex(null);
      }

      mapRef?.current?.setCenter(
        mapData?.[targetSlideIndex as number].coordinates,
        13,
        {
          checkZoomRange: true,
        },
      );
    },
    [activeSlideIndex, mapData, sendDataLayerEventFn],
  );

  return (
    <Box sx={sx}>
      {activeSlideIndex !== null && (
        <OfficePlacemarkBalloonLayout office={mapData?.[activeSlideIndex]} />
      )}
      <YMaps
        query={{
          apikey: YANDEX_MAPS_API_KEY,
        }}
      >
        <Map
          width="100%"
          height="100%"
          // @ts-expect-error
          onLoad={setupMap}
          instanceRef={mapRef}
          options={{
            suppressMapOpenBlock: true,
          }}
          state={{
            zoom: 12,
            controls: [],
            center: (firstOfficeWithCoordinates ||
              DEFAULT_LOCATION) as number[],
          }}
          modules={[
            "domEvent.manager",
            "templateLayoutFactory",
            "geoObject.addon.balloon",
            "shape.Rectangle",
            "geometry.pixel.Rectangle",
          ]}
        >
          <ZoomControl
            options={{
              position: {
                top: 16,
                right: 16,
              },
              // @ts-expect-error
              layout: GetLayout({
                ymaps,
                locale,
                messages,
                type: ZOOM_TYPE,
                component: ZoomLayout,
              }),
            }}
          />

          {mapData &&
            mapData.map((office, index) => {
              return (
                <Placemark
                  key={office.id}
                  geometry={office.coordinates}
                  modules={["geoObject.addon.balloon", "geoObject.addon.hint"]}
                  properties={{
                    index,
                    office,
                  }}
                  options={{
                    iconOffset: [0, -40],
                    openEmptyBalloon: false,
                    hideIconOnBalloonOpen: false,
                    iconLayout: GetLayout({
                      ymaps,
                      locale,
                      messages,
                      type: PLACEMARK_TYPE,
                      component: OfficePlacemarkLayout,
                      props: {
                        tour: office,
                        isPhone: true,
                        onClick: onPlacemarkClick,
                        isActive: activeSlideIndex === index,
                      },
                    }),
                  }}
                />
              );
            })}
        </Map>
      </YMaps>
    </Box>
  );
});
