import { Typography } from "@mui/material";

import { styles } from "../styles";

export const PlacemarkLayout = ({ tour, active, isPhone = false }) => {
  return (
    <Typography
      component="div"
      sx={
        isPhone
          ? styles.placemark.mobile(active)
          : styles.placemark.desktop(active)
      }
    >
      {tour.price
        ? `${tour.price.toLocaleString("ru-RU")}\u00A0₸`
        : "Цена\u00A0неизвестна"}
    </Typography>
  );
};
