/* eslint-disable effector/keep-options-order */
import { createApi, createEvent, createStore } from "effector";

import { ESearchType } from "../../types/search";

export const $tourId = createStore<null | string>(null);
export const $per_page = createStore<number>(9999);
export const $favoriteTours = createStore<[]>([]);
export const $searchType = createStore<ESearchType>(ESearchType.Main);
export const $isTabletMapOpen = createStore<boolean>(false);
export const $isMobileMapOpen = createStore<boolean>(false);
export const $isMobileSortDrawer = createStore<boolean>(false);
export const $isMobileFilterDrawer = createStore<boolean>(false);
export const $isMobileSearchDrawerOpen = createStore<boolean>(false);

export const setTourId = createEvent<null | string>();
export const setSearchType = createEvent<ESearchType>();
export const setMinPrice = createEvent<number>();

$tourId.on(setTourId, (_, payload) => payload);
$searchType.on(setSearchType, (_, payload) => payload);

export const { openMobileSortDrawer, closeMobileSortDrawer } = createApi(
  $isMobileSortDrawer,
  {
    openMobileSortDrawer: () => true,
    closeMobileSortDrawer: () => false,
  },
);

export const { openMobileFilterDrawer, closeMobileFilterDrawer } = createApi(
  $isMobileFilterDrawer,
  {
    openMobileFilterDrawer: () => true,
    closeMobileFilterDrawer: () => false,
  },
);

export const { openMobileMap, closeMobileMap } = createApi($isMobileMapOpen, {
  openMobileMap: () => true,
  closeMobileMap: () => false,
});

export const { openTabletMap, closeTabletMap, toggleTabletMap } = createApi(
  $isTabletMapOpen,
  {
    openTabletMap: () => true,
    closeTabletMap: () => false,
    toggleTabletMap: (state) => !state,
  },
);

export const { openMobileSearchDrawer, closeMobileSearchDrawer } = createApi(
  $isMobileSearchDrawerOpen,
  {
    openMobileSearchDrawer: () => true,
    closeMobileSearchDrawer: () => false,
  },
);
