import { Box, Typography } from "@mui/material";

import { styles as officesPlacemarkStyles } from "@/entities/map/ui/OfficesMap/styles";
import { styles } from "@/entities/map/ui/styles";

import SmallKaztourLogo from "@/public/img/logos/SmallKaztourLogo.svg";

export const OfficePlacemarkBalloonLayout = ({ office }) => {
  return (
    <Box sx={styles.officeBalloon}>
      <SmallKaztourLogo />

      <Box sx-={officesPlacemarkStyles.placemarkWrapper}>
        <Typography sx={officesPlacemarkStyles.officeName}>
          {office?.name}
        </Typography>
        <Typography sx={officesPlacemarkStyles.officeAddress}>
          {office?.address}
        </Typography>
        <Typography sx={officesPlacemarkStyles.officeSchedule}>
          {office?.schedule}
        </Typography>
      </Box>
    </Box>
  );
};
