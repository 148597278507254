import IconButton from "@mui/material/IconButton";

import { styles } from "../styles";

import MaximizeIcon from "@/public/icons/map/maximize.svg";
import MinimizeIcon from "@/public/icons/map/minimaze.svg";

export const FullScreenLayout = ({ active, onClick }) => {
  const stylesKey = "desktop";

  return (
    <IconButton
      onClick={onClick}
      sx={[styles.controlButton, styles.controlButton[stylesKey]]}
    >
      {active ? (
        <MinimizeIcon
          style={{
            ...styles.controlIcon,
            ...styles.controlIcon[stylesKey],
          }}
        />
      ) : (
        <MaximizeIcon
          style={{
            ...styles.controlIcon,
            ...styles.controlIcon[stylesKey],
          }}
        />
      )}
    </IconButton>
  );
};
