"use client";
import Box from "@mui/material/Box";
import { createRoot } from "react-dom/client";

import { MapProviders } from "./MapProviders";

import {
  ZOOM_TYPE,
  SEARCH_TYPE,
  BALLOON_TYPE,
  PLACEMARK_TYPE,
  FULLSCREEN_TYPE,
} from "@/entities/map/lib/constants";

export const GetLayout = ({
  type,
  ymaps,
  props,
  locale,
  messages,
  component: Component,
}) => {
  if (!ymaps) return null;

  if (type === PLACEMARK_TYPE) {
    const Layout = ymaps.templateLayoutFactory.createClass(
      `<div class="${PLACEMARK_TYPE}-${props.tour.id}"/>`,
      {
        build: function () {
          Layout.superclass.build.call(this);

          this.getData().options.set("shape", {
            type: "Rectangle",
            coordinates: [
              [0, 0],
              [71, 30],
            ],
          });

          if (!this.inited) {
            this.inited = true;
            this.active = false;

            const geoObject = this.getData().geoObject;

            // <-- TODO: refactor
            // for mobile swiper drawer
            geoObject.events.add(
              ["click"],
              function () {
                if (!props?.isPhone) return;

                props?.onClick(props?.tour?.id, this.active);

                this.active = !this.active;
                this.rebuild();
              },
              this,
            );

            geoObject.events.add(
              ["reset"],
              function () {
                this.active = false;
                this.rebuild();
              },
              this,
            );

            geoObject.events.add(
              ["onSwipe"],
              function () {
                this.active = !this.active;
                this.rebuild();
              },
              this,
            );
            // -->

            geoObject.events.add(
              ["balloonopen"],
              function () {
                this.active = true;
                this.rebuild();
              },
              this,
            );

            geoObject.events.add(
              ["balloonclose"],
              function () {
                this.active = false;
                this.rebuild();
              },
              this,
            );
          }

          const div = document.querySelector(`.${type}-${props.tour.id}`);
          const root = createRoot(div);
          root.render(
            <Box>
              <MapProviders locale={locale} messages={messages}>
                <Component {...props} active={this.active} />
              </MapProviders>
            </Box>,
          );
        },
      },
    );

    return Layout;
  }

  if (type === BALLOON_TYPE) {
    const Layout = ymaps.templateLayoutFactory.createClass(
      `<div class="${BALLOON_TYPE}-${props.tour.id}" style="display: inline-block"/>`,
      {
        _isElement: function (element) {
          if (element) {
            return element;
          }

          return null;
        },
        getShape: function () {
          if (!this._isElement(this._$element)) {
            return Layout.superclass.getShape.call(this);
          }

          const position = {
            top: this._$element.offsetTop,
            left: this._$element.offsetLeft,
          };

          return new ymaps.shape.Rectangle(
            new ymaps.geometry.pixel.Rectangle([
              [position.left, position.top],
              [position.left, position.top],
            ]),
          );
        },
        build: function () {
          Layout.superclass.build.call(this);

          this._$element = this.getParentElement().getElementsByClassName(
            `${type}-${props.tour.id}`,
          )[0];

          const div = document.querySelector(`.balloon-${props.tour.id}`);
          const root = createRoot(div);
          root.render(
            <Box>
              <MapProviders locale={locale} messages={messages}>
                <Component
                  {...props}
                  close={() => this.events.fire("userclose")}
                />
              </MapProviders>
            </Box>,
          );
        },
      },
    );

    return Layout;
  }

  if (type === ZOOM_TYPE) {
    const Layout = ymaps.templateLayoutFactory.createClass(
      `<div class="${ZOOM_TYPE}"/>`,
      {
        zoomIn: function () {
          const map = this.getData().control.getMap();
          map.setZoom(map.getZoom() + 1, {
            duration: 300,
            checkZoomRange: true,
          });
        },
        zoomOut: function () {
          const map = this.getData().control.getMap();
          map.setZoom(map.getZoom() - 1, {
            duration: 300,
            checkZoomRange: true,
          });
        },
        build: function () {
          Layout.superclass.build.call(this);

          const div = document.querySelector(`.${ZOOM_TYPE}`);
          const root = createRoot(div);
          root.render(
            <Box>
              <MapProviders locale={locale} messages={messages}>
                <Component
                  {...props}
                  zoomIn={this.zoomIn.bind(this)}
                  zoomOut={this.zoomOut.bind(this)}
                />
              </MapProviders>
            </Box>,
          );
        },
      },
    );

    return Layout;
  }

  if (type === FULLSCREEN_TYPE) {
    const Layout = ymaps.templateLayoutFactory.createClass(
      `<div class="${FULLSCREEN_TYPE}"/>`,
      {
        build: function () {
          Layout.superclass.build.call(this);

          const div = document.querySelector(`.${FULLSCREEN_TYPE}`);
          const root = createRoot(div);
          root.render(
            <Box>
              <MapProviders locale={locale} messages={messages}>
                <Component
                  active={this.active}
                  onClick={this.click.bind(this)}
                />
              </MapProviders>
            </Box>,
          );
        },
        click: function () {
          const map = this.getData().control.getMap();

          const searchResultsContainer = document.querySelector(
            `[data=${SEARCH_TYPE}]`,
          );

          if (searchResultsContainer?.style?.display === "none") {
            searchResultsContainer.style.display = "block";
          } else {
            searchResultsContainer.style.display = "none";
          }

          this.active = !this.active;

          map.container.fitToViewport();

          this.rebuild();
        },
      },
    );

    return Layout;
  }
};
