"use client";
import { memo, useState, useEffect } from "react";

import { Box, SwipeableDrawer } from "@mui/material";
import { useUnit } from "effector-react";
import { Virtual } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import { styles } from "../styles";

import { PlacemarkBalloonLayout } from "@/entities/map/ui";

import { closeMobileMap } from "@/shared/model/store/common";

const DRAWER_BLEEDING = 40;
const DRAWER_HEIGHT = 345;

export const PlacemarksSwiperLayout = memo(
  ({
    onSwipe,
    mapData,
    mapIsOpen,
    hasHotelData,
    swiperIsOpen,
    setSwiperOpen,
    activeSlideIndex,
    onSwipeableAreaClose,
  }) => {
    const closeMobileMapFn = useUnit(closeMobileMap);

    const [swiper, setSwiper] = useState(null);

    useEffect(() => {
      if (!mapIsOpen) return;

      // https://github.com/nolimits4web/swiper/issues/5635
      if (swiper && !swiper.destroyed) {
        swiper.slideTo(activeSlideIndex);
      }
    }, [activeSlideIndex, mapIsOpen, swiper]);

    useEffect(() => {
      if (!mapIsOpen) {
        setSwiperOpen(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mapIsOpen]);

    return (
      mapData?.length && (
        <SwipeableDrawer
          hideBackdrop
          anchor="bottom"
          open={swiperIsOpen}
          disableSwipeToOpen={false}
          onClose={onSwipeableAreaClose}
          swipeAreaWidth={DRAWER_BLEEDING}
          onOpen={() => setSwiperOpen(true)}
          paperSx={styles.mobileSwipeableDrawer.paper}
          ModalProps={{
            keepMounted: true,
          }}
          sx={styles.mobileSwipeableDrawer.wrapper({
            mapIsOpen: mapIsOpen,
            drawerHeight: DRAWER_HEIGHT,
            drawerBleeding: DRAWER_BLEEDING,
          })}
        >
          <Box
            sx={styles.mobileSwipeableDrawer.content({
              mapIsOpen: mapIsOpen,
              drawerBleeding: DRAWER_BLEEDING,
            })}
          >
            <Box sx={styles.mobileSwipeableDrawer.swipeableArea}>
              <Box sx={styles.mobileSwipeableDrawer.swipeableArea.strip} />
            </Box>

            <Box sx={{ overflow: "auto" }}>
              <Swiper
                virtual
                // https://github.com/nolimits4web/Swiper/issues/6343
                // loop
                spaceBetween={16}
                modules={[Virtual]}
                slidesPerView={1.2}
                onSwiper={setSwiper}
                centeredSlides={true}
                onSlideChange={(swiper) => {
                  const coordinates =
                    mapData?.[swiper?.activeIndex]?.coordinates;

                  onSwipe(coordinates);
                }}
              >
                {mapData.map((item, index) => {
                  return (
                    <SwiperSlide key={item.id} virtualIndex={index}>
                      <PlacemarkBalloonLayout
                        tour={item}
                        isPhone={true}
                        hasHotelData={hasHotelData}
                        closeMobileMapModal={closeMobileMapFn}
                      />
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </Box>
          </Box>
        </SwipeableDrawer>
      )
    );
  },
);
