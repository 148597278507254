import {
  Box,
  Stack,
  Rating,
  Button,
  SvgIcon,
  IconButton,
  Typography,
} from "@mui/material";
import isNumber from "lodash/isNumber";
import { useTranslations } from "next-intl";

import { styles, tourCard } from "../styles";

import { Condition } from "@/shared/lib/condition";
import { priceFormatter } from "@/shared/lib/helpers";
import { Badge, Image, HotelRating, TooltipWithTextSlice } from "@/shared/ui";

import ImageIcon from "@/public/icons/common/image.svg";
import CloseMapBalloonIcon from "@/public/icons/map/close.svg";

export const PlacemarkBalloonLayout = ({
  tour,
  close,
  isPhone,
  hasHotelData,
  closeMobileMapModal,
}) => {
  const t = useTranslations();
  const urlSearchParams = window.location.search;

  const stylesKey = isPhone ? "mobile" : "desktop";

  return (
    <Box sx={styles.balloon[stylesKey]}>
      {!isPhone && (
        <IconButton
          onClick={close}
          sx={{
            ...styles.controlButton,
            ...styles.controlButton[stylesKey],
            alignSelf: "flex-end",
          }}
        >
          <CloseMapBalloonIcon
            style={{
              ...styles.controlIcon,
              width: "24px",
              height: "24px",
            }}
          />
        </IconButton>
      )}

      <Box sx={styles.balloon[stylesKey].contentWithImageWrapper}>
        <Box>
          {tour?.image ? (
            <Box sx={styles.balloon[stylesKey].imageWrapper}>
              <Image
                quality={75}
                alt={tour.name}
                src={tour?.image?.src}
                height={isPhone ? 150 : 136}
                imageWrapperSx={styles.balloon[stylesKey].image}
              />
            </Box>
          ) : (
            <Box sx={styles.balloon[stylesKey].imagePlaceholder}>
              <SvgIcon
                inheritViewBox
                component={ImageIcon}
                sx={styles.balloon[stylesKey].imagePlaceholderIcon}
              />
            </Box>
          )}
        </Box>

        <Box sx={styles.balloon[stylesKey].content}>
          <Stack gap={isPhone ? "8px" : "6px"}>
            <Box sx={styles.balloon[stylesKey].content.headingBox}>
              {tour?.category?.value && (
                <>
                  <Condition match={isNumber(tour.category.value)}>
                    <Rating
                      readOnly
                      max={Number(tour.category.value)}
                      value={Number(tour.category.value)}
                      sx={tourCard.heading.headingBox.ratingComponent}
                    />
                  </Condition>
                  <Condition match={!isNumber(tour.category.value)}>
                    <Badge size="small" sx={styles.categoryTag}>
                      {tour.category.value}
                    </Badge>
                  </Condition>
                </>
              )}

              <HotelRating rating={tour?.rating || 0} />
            </Box>

            <TooltipWithTextSlice
              sliceAt={30}
              title={tour.name}
              textSx={styles.balloon[stylesKey].title}
            />

            <Typography component="p" sx={styles.balloon[stylesKey].price}>
              от {priceFormatter(tour.price)}
            </Typography>

            <Button
              fullWidth
              disableElevation
              variant="contained"
              sx={{
                ...tourCard.buyButton,
                ...styles.balloon[stylesKey].buyButton,
              }}
              onClick={() => {
                if (hasHotelData) {
                  closeMobileMapModal();
                } else {
                  window.open(
                    `/oteli/${tour.slug}${urlSearchParams}`,
                    "_blank",
                  );
                }
              }}
            >
              {t("SEE_TOUR")}
            </Button>
          </Stack>
        </Box>
      </Box>
    </Box>
  );
};
