import { FC, ReactNode } from "react";

import { AbstractIntlMessages, NextIntlClientProvider } from "next-intl";

import ThemeRegistry from "@/shared/ui/styles/theme/ThemeRegistry";

type TProps = {
  locale: string;
  children: ReactNode;
  messages: AbstractIntlMessages;
};

export const MapProviders: FC<TProps> = ({ locale, children, messages }) => {
  return (
    <ThemeRegistry>
      <NextIntlClientProvider locale={locale} messages={messages}>
        {children}
      </NextIntlClientProvider>
    </ThemeRegistry>
  );
};
