"use client";
import { memo } from "react";

import { Box, useMediaQuery } from "@mui/material";

import { styles } from "../styles";

import { Close, Filters, PlacemarksSwiperLayout } from "@/entities/map/ui";

import { Condition } from "@/shared/lib/condition";
import { useCustomTheme } from "@/shared/lib/hooks/useCustomTheme";

// eslint-disable-next-line react/display-name
export const Controls = memo(
  ({
    onSwipe,
    onClose,
    mapData,
    mapIsOpen,
    hasHotelData,
    swiperIsOpen,
    setSwiperOpen,
    activeSlideIndex,
    onSwipeableAreaClose,
  }) => {
    const theme = useCustomTheme();
    const isPhone = useMediaQuery(theme.breakpoints.down("md"));

    return (
      <>
        <Box sx={styles.mobileControlsWrapper}>
          <Box sx={styles.mobileControlsWrapper.controls}>
            <Close onClick={onClose} isPhone={isPhone} />
            <Condition match={!hasHotelData}>
              <Filters isPhone={isPhone} />
            </Condition>
          </Box>
        </Box>

        <PlacemarksSwiperLayout
          mapData={mapData}
          onSwipe={onSwipe}
          onClose={onClose}
          mapIsOpen={mapIsOpen}
          swiperIsOpen={swiperIsOpen}
          hasHotelData={hasHotelData}
          setSwiperOpen={setSwiperOpen}
          activeSlideIndex={activeSlideIndex}
          onSwipeableAreaClose={onSwipeableAreaClose}
        />
      </>
    );
  },
);
