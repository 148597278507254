"use client";
import { memo, useRef, useMemo, useState, useEffect } from "react";

import Box from "@mui/material/Box";
import {
  Map,
  YMaps,
  Button,
  Placemark,
  Clusterer,
  ZoomControl,
} from "@pbe/react-yandex-maps";
import { useUnit } from "effector-react";
import isEqual from "lodash/isEqual";
import { useLocale, useMessages } from "next-intl";

// TODO: public api fsd
import { $minPrice } from "@/pagesLayer/oteli/[hotelSlug]/model";

import {
  ZOOM_TYPE,
  BALLOON_TYPE,
  PLACEMARK_TYPE,
  FULLSCREEN_TYPE,
  DEFAULT_LOCATION,
} from "@/entities/map/lib/constants";
import {
  GetLayout,
  ZoomLayout,
  PlacemarkLayout,
  FullScreenLayout,
  PlacemarkBalloonLayout,
} from "@/entities/map/ui";

import { YANDEX_MAPS_API_KEY } from "@/shared/config";
import { Condition } from "@/shared/lib/condition";

import LocationPrimaryIcon from "@/public/icons/map/location.svg";

// eslint-disable-next-line react/display-name
export const SearchMap = memo(
  ({
    sx,
    hotelData,
    toursData,
    isTablet = false,
    minimized = false,
    isHotelPage = false,
  }) => {
    const messages = useMessages();
    const locale = useLocale();
    const [ymaps, setYmaps] = useState(null);
    const mapRef = useRef(null);
    const tourMinPrice = useUnit($minPrice);

    const mapData = useMemo(() => {
      if (!toursData?.data && !hotelData) {
        return null;
      }

      if (hotelData) {
        const { id, url, lon, lat, slug, name, images, rating, category } =
          hotelData;
        return [
          {
            id,
            url,
            slug,
            name,
            rating,
            category,
            image: images?.[0],
            price: tourMinPrice,
            coordinates: [lat, lon],
          },
        ];
      }
      return toursData?.data
        .filter(({ hotel }) => hotel?.lat && hotel?.lon)
        .map(({ id, url, price, hotel }) => {
          const { lon, lat, slug, name, images, rating, category } = hotel;
          return {
            id,
            url,
            slug,
            name,
            price,
            rating,
            category,
            image: images?.[0],
            coordinates: [lat, lon],
          };
        });
    }, [toursData, hotelData, tourMinPrice]);

    useEffect(() => {
      const foundCoordinates = mapData?.find(
        (item) => item.coordinates.filter((item) => item)?.length,
      )?.coordinates;

      if (!mapRef || !foundCoordinates) return;

      mapRef?.current?.setCenter(foundCoordinates, 12, {
        checkZoomRange: true,
      });
    }, [mapData, mapRef]);

    const setupMap = (ymaps) => {
      const targetElement = document.querySelector("body");

      ymaps.domEvent.manager
        .group(targetElement)
        .add("click", function (event) {
          const target = event.get("target");
          const parent = target.closest("[data-hotel]");
          const coordinates = JSON.parse(parent?.dataset?.hotel || null);

          const newCenter = coordinates
            ?.filter((item) => !!item)
            ?.map((item) => Number(item));

          if (!newCenter?.length) return;

          // TODO: refactor
          mapRef?.current?.setCenter(newCenter, 18).then(() => {
            setTimeout(() => {
              const targetPlacemark = mapRef?.current?.geoObjects
                ?.get(0)
                ?.getGeoObjects()
                ?.find((geoObject) => {
                  if (
                    isEqual(geoObject.geometry?.getCoordinates(), newCenter)
                  ) {
                    return geoObject;
                  }
                });

              targetPlacemark?.balloon?.open();
            }, 50);
          });
        });

      setYmaps(ymaps);
    };

    // because first tour with 0 index might not have coordinates
    const firstTourWithCoordinates = useMemo(() => {
      return mapData?.find((tour) => tour.coordinates)?.coordinates;
    }, [mapData]);

    return (
      <Box sx={sx}>
        <YMaps
          query={{
            apikey: YANDEX_MAPS_API_KEY,
          }}
        >
          <Map
            width="100%"
            height="100%"
            onLoad={setupMap}
            instanceRef={mapRef}
            options={{
              suppressMapOpenBlock: true,
            }}
            state={{
              zoom: 12,
              controls: [],
              center: firstTourWithCoordinates || DEFAULT_LOCATION,
            }}
            modules={[
              "domEvent.manager",
              "templateLayoutFactory",
              "geoObject.addon.balloon",
              "shape.Rectangle",
              "geometry.pixel.Rectangle",
            ]}
          >
            <Condition match={!isTablet && !isHotelPage}>
              <Button
                options={{
                  maxWidth: "100px",
                  position: {
                    top: 16,
                    left: 16,
                  },
                  layout: GetLayout({
                    ymaps,
                    locale,
                    messages,
                    type: FULLSCREEN_TYPE,
                    component: FullScreenLayout,
                  }),
                }}
              />
            </Condition>

            <Condition match={isHotelPage}>
              <ZoomControl
                options={{
                  position: {
                    top: 380,
                    right: 16,
                  },
                  layout: GetLayout({
                    ymaps,
                    locale,
                    messages,
                    type: ZOOM_TYPE,
                    component: ZoomLayout,
                  }),
                }}
              />
            </Condition>

            <Condition match={!isHotelPage}>
              <ZoomControl
                options={{
                  position: {
                    top: 16,
                    right: 16,
                  },
                  layout: GetLayout({
                    ymaps,
                    locale,
                    messages,
                    type: ZOOM_TYPE,
                    component: ZoomLayout,
                  }),
                }}
              />
            </Condition>

            <Clusterer
              options={{
                maxZoom: 16,
                hasHint: false,
                hasBalloon: false,
                preset: "islands#darkBlueClusterIcons",
              }}
            >
              {mapData &&
                mapData.map((tour, index) => {
                  return (
                    <Placemark
                      key={tour.id}
                      geometry={tour.coordinates}
                      modules={[
                        "geoObject.addon.balloon",
                        "geoObject.addon.hint",
                      ]}
                      properties={{
                        tour,
                        index,
                        balloonContent: tour,
                      }}
                      options={{
                        iconOffset: [0, -32],
                        openEmptyBalloon: true,
                        balloonPanelMaxMapArea: 0,
                        balloonOffset: [-80, -385],
                        hideIconOnBalloonOpen: false,
                        balloonLayout:
                          !minimized &&
                          GetLayout({
                            ymaps,
                            locale,
                            messages,
                            props: { tour },
                            type: BALLOON_TYPE,
                            component: PlacemarkBalloonLayout,
                          }),
                        iconLayout: minimized
                          ? GetLayout({
                              ymaps,
                              locale,
                              messages,
                              props: { tour: {} },
                              type: PLACEMARK_TYPE,
                              component: LocationPrimaryIcon,
                            })
                          : GetLayout({
                              ymaps,
                              locale,
                              messages,
                              props: { tour },
                              type: PLACEMARK_TYPE,
                              component: PlacemarkLayout,
                            }),
                      }}
                    />
                  );
                })}
            </Clusterer>
          </Map>
        </YMaps>
      </Box>
    );
  },
);
