"use client";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { useUnit } from "effector-react";
import { useTranslations } from "next-intl";

import { styles } from "../styles";

import {
  closeMobileMap,
  openMobileFilterDrawer,
} from "@/shared/model/store/common";

import FiltersIcon from "@/public/icons/common/filter.svg";

export const Filters = ({ isPhone }) => {
  const stylesKey = isPhone ? "mobile" : "desktop";
  const t = useTranslations();

  const [openMobileFilterDrawerFn, closeMobileMapFn] = useUnit([
    openMobileFilterDrawer,
    closeMobileMap,
  ]);

  const handleClick = () => {
    openMobileFilterDrawerFn();
    closeMobileMapFn();
  };

  return (
    <IconButton
      onClick={handleClick}
      sx={[styles.filtersButton, styles.filtersButton[stylesKey]]}
    >
      <Typography sx={styles.filtersButton.label}>{t("FILTERS")}</Typography>
      <FiltersIcon sx={[styles.controlIcon, styles.controlIcon[stylesKey]]} />
    </IconButton>
  );
};
