import { Box, useMediaQuery } from "@mui/material";
import IconButton from "@mui/material/IconButton";

import { styles } from "../styles";

import { useCustomTheme } from "@/shared/lib/hooks/useCustomTheme";

import MapMinusIcon from "@/public/icons/map/minus.svg";
import MapPlusIcon from "@/public/icons/map/plus.svg";

export const ZoomLayout = ({ zoomIn, zoomOut }) => {
  const theme = useCustomTheme();
  const isPhone = useMediaQuery(theme.breakpoints.down("md"));

  const stylesKey = isPhone ? "mobile" : "desktop";

  return (
    <Box sx={styles.zoomControlsWrapper}>
      <IconButton
        onClick={() => zoomIn()}
        sx={[styles.controlButton, styles.controlButton[stylesKey]]}
      >
        <MapPlusIcon
          style={{
            ...styles.controlIcon,
            ...styles.controlIcon[stylesKey],
          }}
        />
      </IconButton>

      <IconButton
        onClick={() => zoomOut()}
        sx={[styles.controlButton, styles.controlButton[stylesKey]]}
      >
        <MapMinusIcon
          style={{
            ...styles.controlIcon,
            ...styles.controlIcon[stylesKey],
          }}
        />
      </IconButton>
    </Box>
  );
};
