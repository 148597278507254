"use client";
import {
  memo,
  useRef,
  useMemo,
  useState,
  forwardRef,
  useCallback,
} from "react";

import { Box } from "@mui/material";
import {
  Map,
  YMaps,
  Clusterer,
  Placemark,
  ZoomControl,
} from "@pbe/react-yandex-maps";
import { useUnit } from "effector-react";
import isEqual from "lodash/isEqual";
import { useLocale, useMessages } from "next-intl";

import { styles } from "./styles";

import { $minPrice } from "@/pagesLayer/oteli/[hotelSlug]/model";

import {
  ZOOM_TYPE,
  PLACEMARK_TYPE,
  DEFAULT_LOCATION,
} from "@/entities/map/lib/constants";
import {
  Controls,
  GetLayout,
  ZoomLayout,
  PlacemarkLayout,
} from "@/entities/map/ui";

import { YANDEX_MAPS_API_KEY } from "@/shared/config";
import { closeMobileMap, $isMobileMapOpen } from "@/shared/model/store/common";
import { DialogWrapper } from "@/shared/ui";

const MobileMapWrapper = memo(
  forwardRef(function ({ sx, mapData, onPlacemarkClick }, ref) {
    const messages = useMessages();
    const locale = useLocale();
    const [ymaps, setYmaps] = useState(null);

    const setupMap = (ymaps) => {
      setYmaps(ymaps);
    };

    // because first tour with 0 index might not have coordinates
    const firstTourWithCoordinates = useMemo(() => {
      return mapData?.find((tour) => tour.coordinates)?.coordinates;
    }, [mapData]);

    return (
      <Box sx={sx}>
        <YMaps
          query={{
            apikey: YANDEX_MAPS_API_KEY,
          }}
        >
          <Map
            width="100%"
            height="100%"
            onLoad={setupMap}
            instanceRef={ref}
            options={{
              suppressMapOpenBlock: true,
            }}
            state={{
              zoom: 12,
              controls: [],
              center: firstTourWithCoordinates || DEFAULT_LOCATION,
            }}
            modules={[
              "domEvent.manager",
              "templateLayoutFactory",
              "geoObject.addon.balloon",
              "shape.Rectangle",
              "geometry.pixel.Rectangle",
            ]}
          >
            <ZoomControl
              options={{
                position: {
                  top: 240,
                  right: 16,
                },
                layout: GetLayout({
                  ymaps,
                  locale,
                  messages,
                  type: ZOOM_TYPE,
                  component: ZoomLayout,
                }),
              }}
            />

            <Clusterer
              options={{
                maxZoom: 16,
                hasHint: false,
                hasBalloon: false,
                preset: "islands#darkBlueClusterIcons",
              }}
            >
              {mapData &&
                mapData.map((tour, index) => {
                  return (
                    <Placemark
                      key={tour.id}
                      geometry={tour.coordinates}
                      modules={[
                        "geoObject.addon.balloon",
                        "geoObject.addon.hint",
                      ]}
                      properties={{
                        tour,
                        index,
                        balloonContent: tour,
                      }}
                      options={{
                        iconOffset: [-32, -32],
                        openBalloonOnClick: false,
                        hideIconOnBalloonOpen: false,
                        iconLayout: GetLayout({
                          ymaps,
                          locale,
                          messages,
                          type: PLACEMARK_TYPE,
                          component: PlacemarkLayout,
                          props: {
                            tour,
                            isPhone: true,
                            onClick: onPlacemarkClick,
                          },
                        }),
                      }}
                    />
                  );
                })}
            </Clusterer>
          </Map>
        </YMaps>
      </Box>
    );
  }),
);

export const MobileMap = memo(({ sx, hotelData, toursData }) => {
  const mapRef = useRef(null);

  const [swiperIsOpen, setSwiperOpen] = useState(false);
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  const [tourMinPrice, closeMobileMapFn, isOpen] = useUnit([
    $minPrice,
    closeMobileMap,
    $isMobileMapOpen,
  ]);

  const hasHotelData = !!hotelData;

  const mapData = useMemo(() => {
    if (!toursData?.data && !hotelData) {
      return null;
    }

    if (hasHotelData) {
      const { id, url, lon, lat, slug, name, images, rating, category } =
        hotelData;
      return [
        {
          id,
          url,
          slug,
          name,
          rating,
          category,
          image: images?.[0],
          price: tourMinPrice,
          coordinates: [lat, lon],
        },
      ];
    }

    return toursData?.data
      .filter(({ hotel }) => hotel?.lat && hotel?.lon)
      .map(({ id, url, price, hotel }) => {
        const { lon, lat, slug, name, images, rating, category } = hotel;
        return {
          id,
          url,
          slug,
          name,
          price,
          rating,
          category,
          image: images?.[0],
          coordinates: [lat, lon],
        };
      });
  }, [toursData?.data, hotelData, hasHotelData, tourMinPrice]);

  const onSwipeableAreaClose = useCallback(() => {
    setSwiperOpen(false);

    const geoObjects = mapRef?.current?.geoObjects?.get(0)?.getGeoObjects();

    // TODO: refactor
    geoObjects?.forEach((geoObject) => {
      geoObject?.events.fire("reset");
    });
  }, []);

  const onSwipe = useCallback((coordinates) => {
    if (!coordinates) return;

    const newCenter = coordinates
      ?.filter((item) => !!item)
      ?.map((item) => Number(item));

    const geoObjects = mapRef?.current?.geoObjects?.get(0)?.getGeoObjects();

    // TODO: refactor
    mapRef?.current?.setCenter(newCenter, 18).then(() => {
      setTimeout(() => {
        geoObjects?.forEach((geoObject) => {
          geoObject?.events.fire("reset");
        });

        geoObjects
          ?.find((geoObject) => {
            if (isEqual(geoObject.geometry?.getCoordinates(), newCenter)) {
              return geoObject;
            }
          })
          ?.events.fire("onSwipe");
      }, 50);
    });
  }, []);

  const onPlacemarkClick = useCallback(
    (id, active) => {
      const targetSlideIndex = mapData?.findIndex((item) => item.id === id);

      if (targetSlideIndex === -1) return;

      if (active) {
        setSwiperOpen(false);
        return;
      }

      if (targetSlideIndex !== activeSlideIndex) {
        setSwiperOpen(true);
        setActiveSlideIndex(targetSlideIndex);
      } else {
        setSwiperOpen(!swiperIsOpen);
      }
    },
    [activeSlideIndex, mapData, swiperIsOpen],
  );

  return (
    <DialogWrapper fullScreen open={isOpen} sx={styles.dialog}>
      <Controls
        mapRef={mapRef}
        onSwipe={onSwipe}
        mapData={mapData}
        mapIsOpen={isOpen}
        onClose={closeMobileMapFn}
        hasHotelData={hasHotelData}
        swiperIsOpen={swiperIsOpen}
        setSwiperOpen={setSwiperOpen}
        activeSlideIndex={activeSlideIndex}
        onSwipeableAreaClose={onSwipeableAreaClose}
      />

      <MobileMapWrapper
        sx={sx}
        ref={mapRef}
        mapData={mapData}
        onPlacemarkClick={onPlacemarkClick}
      />
    </DialogWrapper>
  );
});
